import React from "react";
import { Link } from "gatsby";
import causeImage1 from "../../images/jfp.png";
import TitleSidebar from "./TitleSidebar";

const CausesDetail = () => {
    return (
        <section className="causes-detail-area">
            <div className="container">
                <div className="row blog-content-wrap">
                    <div className="col-lg-8">
                        <div className="blog-content">
                            <div className="blog-item">
                                <div className="blog-img">
                                    <img src={causeImage1} alt="" />
                                </div>
                                <div className="blog-inner-content">
                                    <div className="inner-causes-box">
                                        <h3 className="blog__title">
                                            <Link to="/causes-detail">Jaycee Foundation Patron</Link>
                                        </h3>
                                        <ul className="blog__list">
                                            <li>
                                                <i className="icon-heart"></i> Amount:{" "}
                                                <span> <i className="fa fa-inr"></i> 25,000</span>
                                            </li>
                                            {/*
                                                <li>
                                                    <i className="fa fa-line-chart"></i> Raised:{" "}
                                                    <span>25,270</span>
                                                </li>
                                            */}
                                        </ul>
                                    </div>
                                    <div className="inner-causes-btn">
                                        <Link to="/donate" className="theme-btn">
                                            donate now
                                        </Link>
                                    </div>
                                </div>
                                <div className="blog-inner-content-2 text-justify">
                                    <p className="causes__text">
                                        Jaycee Foundation Patron is the title of Honor awarded to any member of JCI India or any individual or organization who believes in the in the principles and policies of Junior Chamber movement and who donates an amount not less than Rs. 25000/-.
                                    </p>
                                    <p className="causes__text">
                                        The honoree will receive a pin of Honor, a colorful Photo ID card, a plaque of Honor, and will be eligible for the special privileges of the JCI India Foundation. One child will be given with a Scholarship of Rs. 2500/-. They shall be honored during the glittering felicitation ceremony at National Convention of JCI India.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="sidebar-shared">
                            <TitleSidebar />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CausesDetail;
